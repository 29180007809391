import slick from '../plugins/slick.js';
import cookie from '../plugins/jquery.cookie.js';


(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Accordion Block
      $('.the-accordion .toggler').click(function(){
        var $this = $(this).parent('.the-accordion');
        $this.toggleClass('active');
        if ($this.hasClass('active')) {
          setTimeout(function(){
            $this.addClass('shown');
          }, 100 );
        } else {
          $this.removeClass('shown');
        }
      });

      // Search
      $('.search-toggle').click(function(e){
        e.preventDefault();
        $('#search-popup').addClass('active');
        $('#search-input').focus();
      });
      $('.close-search').click(function(){
        $('#search-popup').removeClass('active');
      });

      // Cookied Popup
      $('.close-cookie-popup').on('click', function(){
        $('.cookied-popup-wrapper').css('display', 'none');
        $.cookie('popup', $('.cookied-popup-wrapper').attr('id'), { path: '/' });
      });

      // Status Bar
      $('#status-close').on('click', function(){
        $('.status-bar').css('display', 'none');
        $.cookie('status', $('.status-bar').attr('id'), { path: '/' });
      });

      // Pagination
      $('#pagination').on('click', 'a', function(e){
        e.preventDefault();
        $(this).remove();
        $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('#ajax-posts').append($('#ajax-loader').html());
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Anchor
      $('a').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href.match('^#') && !$(this).hasClass('accordion-tab')) {
          e.preventDefault();
          $('html, body').stop().animate({
              scrollTop: $($href).offset().top
          });
        }
      });

      // Form Labels
      $('input').on('focus', function(){
        $(this).parent('*').addClass('filled');
      });

      $('input').on('blur', function(){
        if ($(this).val() !== '') {
          $(this).parent('*').addClass('filled');
        } else {
          $(this).parent('*').removeClass('filled');
        }
      });

      // Video popups
      $('a').on('click', function(e){
        var $target = $(this).attr('href');
        if ($target.toLowerCase().indexOf("vimeo") >= 0) {
          e.preventDefault();
          var $n = $target.lastIndexOf('/');
          var $vid = $target.substring($n + 1);
          $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("youtu") >= 0 && !$(this).hasClass('social-link')) {
          e.preventDefault();
          var $y = $target.lastIndexOf('=');
          var $yid = $target.substring($y + 1);
          $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
      });
      $('.close-popup').on('click', function(){
        $('.video-popup-wrapper').removeClass('active');
        $('.popup-content').html('');
        $('body, html').removeClass('body-noscroll');
      });

      // Mobile Nav
      $('#mobile-nav-toggle').on('click', function(){
        var $navheight = $('#mobile-navigation').height() + 170;
        $('body, html').toggleClass('nav-locked');
        $('#mobile-navigation-wrapper').toggleClass('active');
        $(this).toggleClass('active');
      });

      // Impact Story Slider
      $('.impact-story-slider').slick({
        dots: false,
        infinite: true,
        speed: 1000,
        fade: true,
        autoplay: false,
        slidesToShow: 1,
        variableWidth: false,
        prevArrow: '<i class="icon-chevron-left arrows prev"></i>',
        nextArrow: '<i class="icon-chevron-right arrows next"></i>',
        adaptiveHeight: true
      });
      $('.impact-story-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.impact-story-slider .highlight').removeClass('visible');
        setTimeout(function(){
          $('.impact-story-slider .highlight').addClass('visible');
        }, 1000);
      });

      // Slideshow Slider
      $('.slideshow-slider').slick({
        dots: false,
        infinite: true,
        speed: 1000,
        fade: false,
        autoplay: false,
        slidesToShow: 1,
        variableWidth: false,
        prevArrow: '<i class="icon-arrow-left arrows prev"></i>',
        nextArrow: '<i class="icon-arrow-right arrows next"></i>',
        adaptiveHeight: true
      });

      // Split Content Block
      $('.split_content').each(function(){
        $(this).children('.inner').css('min-height', $(this).find('.container').height());
      });

      // Animations
      var animations = function(){
        $('.home-hero').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height()) {
            setTimeout(function(){
              $this.addClass('visible');
            }, 100);
            setTimeout(function(){
              $this.addClass('visible2');
            }, 2000);
            setTimeout(function(){
              $this.addClass('visible3');
            }, 2300);
            setTimeout(function(){
              $this.find('.cta:first-child').addClass('visible');
            }, 2300);
            setTimeout(function(){
              $this.find('.cta:nth-child(2)').addClass('visible');
            }, 2700);
            setTimeout(function(){
              $this.find('.cta:nth-child(3)').addClass('visible');
            }, 3100);
            setTimeout(function(){
              $this.find('.cta:nth-child(4)').addClass('visible');
            }, 3500);
          }
        });
        $('.interior-hero').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height()) {
            setTimeout(function(){
              $this.addClass('visible');
            }, 100);
            setTimeout(function(){
              $this.addClass('visible2');
            }, 700);
            setTimeout(function(){
              $this.addClass('visible3');
            }, 1000);
          }
        });
        $('span.highlight').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height()) {
            setTimeout(function(){
              $this.addClass('visible');
            }, 200);
          }
        });
        $('.number').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height()) {
            setTimeout(function(){
              $this.addClass('visible');
            }, 200);
          }
        });
        $('.grid_cta').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
            setTimeout(function(){
              $this.find('.col-md-6:first-child .grid_block').addClass('visible');
            }, 100);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(2) .grid_block').addClass('visible');
            }, 400);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(3) .grid_block').addClass('visible');
            }, 700);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(4) .grid_block').addClass('visible');
            }, 1000);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(5) .grid_block').addClass('visible');
            }, 1300);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(6) .grid_block').addClass('visible');
            }, 1600);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(7) .grid_block').addClass('visible');
            }, 1900);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(8) .grid_block').addClass('visible');
            }, 2200);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(9) .grid_block').addClass('visible');
            }, 2500);
            setTimeout(function(){
              $this.find('.col-md-6:nth-child(10) .grid_block').addClass('visible');
            }, 2800);
          }
        });
        $('.staff').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
            setTimeout(function(){
              $this.find('.col-lg-3:first-child .staff-member').addClass('visible');
            }, 100);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(2) .staff-member').addClass('visible');
            }, 400);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(3) .staff-member').addClass('visible');
            }, 700);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(4) .staff-member').addClass('visible');
            }, 1000);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(5) .staff-member').addClass('visible');
            }, 1300);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(6) .staff-member').addClass('visible');
            }, 1600);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(7) .staff-member').addClass('visible');
            }, 1900);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(8) .staff-member').addClass('visible');
            }, 2200);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(9) .staff-member').addClass('visible');
            }, 2500);
            setTimeout(function(){
              $this.find('.col-lg-3:nth-child(10) .staff-member').addClass('visible');
            }, 2800);
          }
        });
        $('.collage').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
            setTimeout(function(){
              $this.find('.collage-item:first-child').addClass('visible');
            }, 100);
            setTimeout(function(){
              $this.find('.collage-item:nth-child(2)').addClass('visible');
            }, 400);
            setTimeout(function(){
              $this.find('.collage-item:nth-child(3)').addClass('visible');
            }, 700);
          }
        });
        $('.split_content .image').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height()) {
            setTimeout(function(){
              $this.addClass('visible');
            }, 500);
          }
        });
        $('.word-by-word:first-of-type').addClass('visible');
        setTimeout(function(){
          $('.word-by-word:nth-of-type(2)').addClass('visible');
        }, 200);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(3)').addClass('visible');
        }, 400);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(4)').addClass('visible');
        }, 600);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(5)').addClass('visible');
        }, 800);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(6)').addClass('visible');
        }, 1000);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(7)').addClass('visible');
        }, 1200);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(8)').addClass('visible');
        }, 1400);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(9)').addClass('visible');
        }, 1600);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(10)').addClass('visible');
        }, 1800);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(11)').addClass('visible');
        }, 2000);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(12)').addClass('visible');
        }, 2200);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(13)').addClass('visible');
        }, 2400);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(14)').addClass('visible');
        }, 2600);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(15)').addClass('visible');
        }, 2800);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(16)').addClass('visible');
        }, 3000);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(17)').addClass('visible');
        }, 3200);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(18)').addClass('visible');
        }, 3400);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(19)').addClass('visible');
        }, 3600);
        setTimeout(function(){
          $('.word-by-word:nth-of-type(20)').addClass('visible');
        }, 3800);
      };

      // Load functions
      $(window).bind('load', function(){
        animations();
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
      });

      // Scroll functions
      $(window).scroll(function(){
        animations();
      });
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page
      $('.hero-slider').slick({
        dots: false,
        infinite: true,
        speed: 1000,
		    fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        variableWidth: false,
        //prevArrow: '<i class="icon-arrow-left arrows prev"></i>',
        //nextArrow: '<i class="icon-arrow-right arrows next"></i>',
        adaptiveHeight: true
        // responsive: [
        //   {
        //     breakpoint: 768,
        //     settings: {
        //       autoplay: false,
        //     }
        //   }
        // ]
      });
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.